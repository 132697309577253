
	export default {
		name: "SimpleCard",
		props: {
			title: {type: String, default: ""},
			titleCls: {type: String, default: ""},
			subtitle: {type: String, default: ""},
			full: {type: Boolean, default: false},
		},
	}
